//
// contact.scss
//

/*********************************/
/*         Contact us            */
/*===============================*/

.form-icon {
  .icons {
    position: absolute;
    top: 13px;
    left: 18px;
  }
}

.map {
  line-height: 0;
  iframe {
    width: 100% !important;
    height: 400px;
  }
}

.error {
  margin: 8px 0px;
  display: none;
  color: $danger;
}

#ajaxsuccess {
  font-size: 16px;
  width: 100%;
  display: none;
  clear: both;
  margin: 8px 0px;
}

.error_message,
#success_page {
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 5px;
}

.error_message {
  background-color: rgba(240, 115, 90, 0.1) !important;
  border: 2px solid rgba(240, 115, 90, 0.1) !important;
  color: #f0735a !important;
  font-size: 14px;
}

.contact-loader {
  display: none;
}

#success_page {
  background-color: rgba(83, 199, 151, 0.1) !important;
  border: 2px solid rgba(83, 199, 151, 0.1) !important;
  color: #53c797 !important;
  p {
    margin-bottom: 0 !important;
  }
}

//Datepicker
.qs-datepicker-container {
  background-color: $datepicker-bg;
  border-color: $border-color;
  color: $datepicker-text;
  box-shadow: $shadow;
  .qs-controls {
    background-color: $gray-200;
    color: $datepicker-text;
    .qs-month-year {
      font-weight: 600;
      font-size: 15px;
      &:hover {
        border-bottom: 1px solid $border-color;
      }
    }
  }
  .qs-squares {
    .qs-square {
      font-size: 15px;
      height: 34px;
      width: 34px;
      &:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover,
      &.qs-current {
        background: $primary;
        color: $white;
        border-radius: 30px;
        font-weight: 500;
        text-decoration: none;
        border-radius: 30px;
      }
    }
    .qs-day {
      font-weight: 500;
      color: $muted;
    }
  }
}

@media (min-width: 768px) {
  .map {
    &.map-height-two {
      iframe {
        height: 551px;
      }
    }
  }
}
